// 系统枚举
import { Request } from '@/http/request'

// 枚举列表
export function enumsList (parameter: any) {
  return Request.axiosInstance.post('/system/enums/list', parameter)
}
// 添加枚举 
export function addEnums (parameter: any) {
    return Request.axiosInstance.post('/system/enums/add', parameter)
}
// 删除枚举 
export function deleteEnums (parameter: any) {
    return Request.axiosInstance.post('/system/enums/delete', parameter)
}
//  查询枚举 
export function getEnums (parameter: any) {
    return Request.axiosInstance.post('/system/enums/get', parameter)
}
// 修改枚举 
export function editEnums (parameter: any) {
    return Request.axiosInstance.post('/system/enums/edit', parameter)
}

export default { enumsList, addEnums, deleteEnums, editEnums, getEnums }